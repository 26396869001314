/* App container */

.app {
  position: relative;
  min-height: 100vh;
}

/* Wrapper */

.wrapper {
  padding-top: 30px;
  padding-bottom:150px
}

/* Navbar */

.navbar-brand a {
  color: #ffffff;
}

.navbar-brand a:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* Footer */

.app-footer {
  color: #ffffff;
  background-color: #212529;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.footer-tagline {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: right;
}

@media (max-width: 575px) {
  .app-footer {
    padding-top:20px;
  }

  .footer-tagline {
    justify-content: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

/* Form wrapper & table wrapper */

.form-wrapper,
.table-wrapper {
  margin: 0 auto;
}

/* Intro text */

.intro-text {
  max-width: 620px;
}

/* Table wrapper */

.table-wrapper .header-link {
  float: right;
  text-align: center;
  margin: 0 0 10px 0;
  padding: 10px 10px;
  background-color: #28a745;
  border-color: #28a745;
  color: #ffffff;
}

.table-wrapper .header-link:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* Form group */

.form-group {
  margin-bottom: 1rem;
}

/* Holder for form submission buttons */

.form-submission {
  margin-bottom: 50px;
}

/* Operation button */ 

.btn-operation {
  background-color: #ffffff !important;
  border:solid 2px #0E872E !important;
  color: #000000 !important;
}

.btn-operation:hover {
  color: #ffffff !important;
  text-decoration: underline !important;
  background-color: #3A6C2B !important;
}

.btn-operation:active {
  color: #ffffff !important;
  background-color: #3A6C2B !important;
}

.btn-operation:focus {
  color: #000000 !important;
  background-color: #ffffff !important;
  text-decoration: underline !important;
  box-shadow: 1px 1px 1px #000000 !important;
  --bs-btn-focus-box-shadow: none !important;
}

/* View details button */ 

.btn-view-details {
  background-color: #0E872E !important;
  border-color: #0E872E !important;
}

.btn-view-details:hover {
  text-decoration: underline !important;
  background-color: #3A6C2B !important;
  border-color: #3A6C2B !important;
}

.btn-view-details:active {
  background-color: #3A6C2B !important;
  border-color: #3A6C2B !important;
}

.btn-view-details:focus {
  background-color: #0E872E !important;
  border-color: #0E872E !important;
  box-shadow: 1px 1px 1px #000000 !important;
  text-decoration: underline !important;
  --bs-btn-focus-box-shadow: none !important;
}

/* Container for URL content */

.url-container {
  overflow-wrap: break-word;
}

/* Data table pagination */

.pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.btn-pagination {
  background-color: #ffffff;
  border:solid 2px #0E872E;
  color: #000000;
}